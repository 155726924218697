import React from 'react';

import { graphql } from 'gatsby';
import { mq, colors, fontSizes } from '../theme';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import Image from '../components/Image';
import { Heading2, Paragraph, Heading3 } from '../components/Heading/Text';
import Link from '../components/Link';
import { slugify } from '../helpers/slugify.js';

const BrandPage = ({ data }) => {
  const brand = data.allBrandsJson.nodes[0];

  return (
    <Layout>
      <SEO title={`L'entreprise partenaire ${brand.name}`} />
      <Container
        background={colors.neutralLight30}
        css={{ alignItems: 'center' }}
      >
        <div
          css={mq({
            position: 'relative',
            backgroundColor: colors.neutralWhite,
            display: 'flex',
            alignItems: 'center',
            width: 128,
            height: 128,
            marginRight: [50, 0],
            marginBottom: [0, 30]
          })}
        >
          <Image
            alt={brand.name}
            src={brand.image}
            css={mq({
              width: 100,
              height: 100,
              marginLeft: ['auto', 'auto'],
              marginRight: ['auto', 'auto']
            })}
          />
        </div>
        <div>
          <Heading2 light>Entreprise {brand.name}</Heading2>
          <Paragraph>
            {[
              brand.ressources &&
                `${brand.ressources} ressource${
                  brand.ressources > 0 ? 's' : ''
                }`,
              brand.ouvrages &&
                `${brand.ouvrages} ouvrage${brand.ouvrages > 0 ? 's' : ''}`
            ]
              .filter(v => v !== null)
              .join(' et ')}
          </Paragraph>
        </div>
      </Container>
      <Container
        background={colors.neutralWhite}
        css={{ flexDirection: 'column' }}
      >
        <Paragraph
          css={{
            h4: {
              marginTop: 30,
              marginBottom: 10
            },
            a: {
              color: colors.brandColor
            },
            'h4 strong': {
              lineHeight: '30px',
              fontSize: fontSizes.big,
              color: colors.neutralDarker
            }
          }}
          dangerouslySetInnerHTML={{ __html: brand.content }}
        />

        <Heading3 light>Métiers associés</Heading3>

        <div css={{ display: 'flex' }}>
          {brand.metiers.map(metier => (
            <Link
              to={`/metiers/${slugify(metier)}`}
              key={metier}
              css={{
                position: 'relative',
                backgroundColor: colors.neutralWhite,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 150,
                height: 150,
                marginRight: 20,
                padding: 10,
                border: `1px solid ${colors.neutralLight30}`,
                textAlign: 'center',
                ':hover': {
                  backgroundColor: colors.neutralLight
                }
              }}
            >
              <Image
                src={`jobs/${slugify(metier)}.svg`}
                css={mq({
                  width: 40,
                  height: 40,
                  marginLeft: ['auto', 'auto'],
                  marginRight: ['auto', 'auto'],
                  marginTop: 10,
                  marginBottom: 10
                })}
              />
              <Paragraph css={{ fontSize: fontSizes.medium, marginBottom: 0 }}>
                {metier}
              </Paragraph>
            </Link>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default BrandPage;

export const query = graphql`
  query ($brandId: String!) {
    allBrandsJson(filter: { id: { eq: $brandId } }) {
      nodes {
        id
        metiers
        ressources
        ouvrages
        content
        image
        name
      }
    }
  }
`;
